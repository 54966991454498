<template>
  <div>
   
    <b-tabs pills>
      <b-tab
        v-if="!isUserList && $route.name != 'sub_consumer'"
        @click="activeTab('dealer', false)"
        :active="selected_tab === 'dealer'"
      >
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("SubAccounts.Dealer") }}</span>
        </template>
        <b-card class="mt-36">
          <DealerList
            :userListShow="userListShow"
            :selectedAccount="selectedAccount"
          />
        </b-card>
      </b-tab>
      <b-tab
        @click="activeTab('users', false)"
        :active="selected_tab === 'users'"
        v-if="
          isUserList &&
          ($route.name === 'sub_consumer'
            ? checkAbility({
                action: constants.PERMISSIONS_ACTION.READ,
                subject: constants.PERMISSIONS_MODEL.USER_ACCOUNT_CONSUMER
              })
            : checkAbility({
                action: constants.PERMISSIONS_ACTION.READ,
                subject: constants.PERMISSIONS_MODEL.USER_ACCOUNT
              }))
        "
      >
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("user.User") }}</span>
        </template>

        <b-card class="mt-36">
          <div class="adduser-btn-row">
            <h4 class="card-title">
              {{ this.selectedAccount && this.selectedAccount.name }}
            </h4>
            <div class="role-btn">
              <b-button
                variant="outline-primary"
                size="sm"
                v-if="isUserList"
                class="v2-back"
                 @click="$router.go(-1)"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
              </b-button>
            </div>
          </div>

          <UserListComp :selectedAccount="selectedAccount" />
        </b-card>
      </b-tab>

      <b-tab
        :active="selected_tab === 'invitations'"
        @click="activeTab('invitations', false)"
        v-if="
          isUserList &&
          ($route.name === 'sub_consumer'
            ? checkAbility({
                action: constants.PERMISSIONS_ACTION.READ,
                subject: constants.PERMISSIONS_MODEL.VW_CHILDREN_ACCOUNTS
              })
            : checkAbility({
                action: constants.PERMISSIONS_ACTION.READ,
                subject: constants.PERMISSIONS_MODEL.VW_USER_ACCOUNTS
              }))
        "
      >
        <template #title>
          <feather-icon icon="UserPlusIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("user.invitations") }}</span>
        </template>
        <b-card class="mt-36">
          <div
            class="adduser-btn-row"
            v-if="
              $route.name === 'sub_consumer'
                ? checkAbility({
                    action: constants.PERMISSIONS_ACTION.ADD,
                    subject: constants.PERMISSIONS_MODEL.VW_CHILDREN_ACCOUNTS
                  })
                : checkAbility({
                    action: constants.PERMISSIONS_ACTION.ADD,
                    subject: constants.PERMISSIONS_MODEL.VW_USER_ACCOUNTS
                  })
            "
          >
            <h4 class="card-title">
              {{ $t("user.ListInvitations") }}
            </h4>
      <div>
              <b-button
              variant="primary"
              id="AddInvite"
              class="btn-icon"
              v-b-tooltip.hover.v-primary
              :title="$t('tooTip.AddInvite')"
              @click="ShowSentInvitation"
            >
              <feather-icon icon="UserPlusIcon" />
               <span class="d-none d-sm-inline ml-1">{{ $t("tooTip.AddInvite") }}</span>
            </b-button>
            <b-button
                variant="outline-primary"
                size="sm"
                v-if="isUserList"
                class="v2-back ml-10"
                @click="$router.go(-1)"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
              </b-button>
      </div>
          </div>
          <InvitationsList
            :selectedAccount="selectedAccount"
            :isSentInvite="true"
            v-if="
              $route.name === 'sub_consumer'
                ? checkAbility({
                    action: constants.PERMISSIONS_ACTION.READ,
                    subject: constants.PERMISSIONS_MODEL.VW_CHILDREN_ACCOUNTS
                  })
                : checkAbility({
                    action: constants.PERMISSIONS_ACTION.READ,
                    subject: constants.PERMISSIONS_MODEL.VW_USER_ACCOUNTS
                  })
            "
          />
        </b-card>
      </b-tab>
      <b-tab
        v-if="
          !isUserList &&
          $route.name !== 'sub_consumer' &&
          checkAbility({
            action: constants.PERMISSIONS_ACTION.CONSUMER_READ,
            subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
          })
        "
        @click="activeTab('consumer', false)"
        :active="selected_tab === 'consumer'"
      >
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{
            $t("SubAccounts.Consumer")
          }}</span>
        </template>
        <b-card class="mt-36">
          <ConsumerList :userListShow="userListShow" />
        </b-card>
      </b-tab>

      <template #empty v-if="!isUserList && $route.name === 'sub_consumer'">
        <!-- <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{
            $t("SubAccounts.Consumer")
          }}</span> -->
        <b-card class="mt-36 w-100">
          <ConsumerList :userListShow="userListShow" />
        </b-card>
      </template>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BTooltip,
  VBTooltip
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import DealerList from "@/layouts/components/ConsumerOnboarding/DealerList.vue";
import DealerInvitationsList from "@/layouts/components/ConsumerOnboarding/DealerInvitationsList.vue";
import InvitationsList from "@/layouts/components/UserOnboarding/InvitationsList.vue";
import UserListComp from "@/layouts/components/UserOnboarding/UserListComp.vue";
import constants from "@/utils/constants";
import ConsumerList from "@/layouts/components/ConsumerOnboarding/ConsumerList.vue";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    DealerList,
    DealerInvitationsList,
    InvitationsList,
    UserListComp,
    ConsumerList,
    BButton,
    BTooltip,
    VBTooltip
  },
  data() {
    return {
      isUserList: false,
      selectedAccount: {},
      constants,
      isDealerShow: true,
      selected_tab: "users",
      last_selected_tab: null
    };
  },
  mounted() {
    if (
      localStorage.getItem("sub_dealer_breadcrumb") &&
      localStorage.getItem("sub_dealer_breadcrumb").length
    ) {
      this.$route.meta.breadcrumb = JSON.parse(
        localStorage.getItem("sub_dealer_breadcrumb")
      );
    }
    const query = this.$route.query && this.$route.query.tab;
    if (query === "consumer") {
      this.selected_tab = this.$route.query.tab;
    }
    // else if (!query || query === "dealer") {
    //   this.$router.replace({ query: {} });
    // }
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  computed() {
    // if (this.$route.meta.breadcrumb && this.$route.meta.breadcrumb.length) {
    //   this.$route.meta.breadcrumb = JSON.parse(
    //     localStorage.getItem("sub_dealer_breadcrumb")
    //   );
    // }
  },

  watch: {
    $route(to, from) {
      const query = this.$route.query && this.$route.query.tab;
      if (query === "users") {
        this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.filter(
          (e) => e.text !== "Breadcrumb_Invitations"
        );
        this.selected_tab = this.$route.query.tab;
      } else if (query === "invitations") {
        this.$route.meta.breadcrumb.forEach((e) => {
          if (e.index === "sub_acc_user") {
            e.active = false;
            e.to = {
              name: this.$route.name,
              params: this.$route.params,
              query: { tab: "users" }
            };
          }
        });
        this.selected_tab = this.$route.query.tab;
      } else if (query === "consumer") {
        // this.$route.meta.breadcrumb.forEach((e) => {
        //   if (e.index === "sub_acc_user") {
        //     e.active = false;
        //     e.to = {
        //       name: this.$route.name,
        //       params: this.$route.params,
        //       query: { tab: "users" }
        //     };
        //   }
        // });
        this.selected_tab = this.$route.query.tab;
      } else if (query === "dealer") {
        this.selected_tab = this.$route.query.tab;
      }
    }
  },
  methods: {
    ShowSentInvitation() {
      this.$bvModal.show("modal-sm-invitations");
    },
    userListShow(e) {
      this.isUserList = true;
      this.last_selected_tab = this.selected_tab;
      this.selected_tab = "users";
      this.selectedAccount = e;
    },
    dealerShow() {
      this.last_selected_tab && this.last_selected_tab != "users"
        ? this.$router.replace({ query: { tab: this.last_selected_tab } })
        : this.$router.replace({ query: {} });
      if (this.last_selected_tab) {
        this.selected_tab = this.last_selected_tab;
        this.last_selected_tab = null;
      }

      this.isUserList = false;
    },
    backDealerShow() {
      this.isUserList = false;
    },
    activeTab(tab, isRedirect) {
      this.selected_tab = tab;

      this.$router.replace({ query: { tab } }).catch((e) => {});

      if (this.$route.query && this.$route.query.tab === "invitations") {
        this.$route.meta.breadcrumb.forEach((e) => {
          if (e.index === "sub_acc_user") {
            e.active = false;
            e.to = {
              name: this.$route.name,
              params: this.$route.params,
              query: { tab: "users" }
            };
          }
        });
        const isExists = this.$route.meta.breadcrumb.find(
          (e) => e.text === "Breadcrumb_Invitations"
        );
        if (!isExists || !isExists.text) {
          this.$route.meta.breadcrumb.push({
            text: "Breadcrumb_Invitations",
            active: true
          });
        }
      } else if (this.$route.query && this.$route.query.tab === "users") {
        if (this.$route.query && this.$route.query.tab === "users") {
          this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.filter(
            (e) => e.text !== "Breadcrumb_Invitations"
          );
        }
      } else if (this.$route.query && this.$route.query.tab === "consumer") {
        this.$route.meta.breadcrumb[
          this.$route.meta.breadcrumb.length - 1
        ].active = false;
        this.$route.meta.breadcrumb[
          this.$route.meta.breadcrumb.length - 1
        ].to.query = { tab: "dealer" };
      }
    }
  }
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
